<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.model-action {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(68, 125, 245, 1);
  &:hover {
    cursor: pointer;
  }
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <span class="model-action" @click="open">支付账号变更日志</span>
    <a-modal
      title="收款账号修改日志"
      ok-text="确认"
      cancel-text="取消"
      :width="700"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-table
        :columns="columns"
        :loading="loading"
        :dataSource="data"
        :pagination="{
          current, 
          total,
          pageSize: size, 
        }"
        @change="tabChange"
        style="margin-top: 13px"
      >
      </a-table>
    </a-modal>  
  </span>
</template>

<script>
import { changepayaccountloglist } from "@/api/member" 

const columns = [
  {
    title: "客户ID",
    dataIndex: "member_id",
  },
  {
    title: "客户姓名",
    dataIndex: "member_name",
  },
  {
    title: "变更ID",
    dataIndex: "change_log_id",
  },
  {
    title: "修改前",
    dataIndex: "account_before",
  },
  {
    title: "修改后",
    dataIndex: "account_after",
  },
  {
    title: "修改日期",
    dataIndex: "update_time",
  }
];

export default {
  props: {
    member_id: {
      type: Number,
      default: undefined
    },
    member_name: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      columns,
      total: 10,
      size: 10,
      current: 1,
    };
  },
  created() {
  },
  methods: {
    async getLogList() {
      const that = this;
      this.loading = true;
      try {
        let res = await changepayaccountloglist({
          page_size: this.size,
          page: this.current,
          member_id: this.member_id
        });
        if (!res) return;
        this.loading = false;
        this.total = res.total;
        this.data = res.change_log_list.map((item, index) => {
          item["member_name"] = that.member_name;
          item["key"] = item.member_id;
          return item;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    tabChange(pagination, filters, sorter, { currentDataSource }) {
      let { current, pageSize } = pagination;
      this.current = current;
      this.size = pageSize;
      this.getLogList();
    },
    open() {
      this.visible = true;
      this.getLogList(); 
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  }
};
</script>